import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { filterDropdown } from "./multi-select-filter.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MultiSelectFilter({ optionsMap, filteringBy, preSelectedOptions, onSelect }) {
    const theme = useTheme();
    const [option, setOption] = React.useState(
        Array.isArray(preSelectedOptions) ?
            preSelectedOptions.filter(preSelectedOption => optionsMap[preSelectedOption]) :
            []
    );
    const options = Object.keys(optionsMap);

    const handleChange = (event) => {
        onSelect(event.target.value);
        const {
            target: { value },
        } = event;
        setOption(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl className={filterDropdown}>
            <InputLabel id={filteringBy.toLowerCase()}>{filteringBy}</InputLabel>
            <Select
                labelId={filteringBy.toLowerCase()}
                id="filter"
                multiple
                value={option}
                onChange={handleChange}
                input={<OutlinedInput id={"select-" + filteringBy.toLowerCase()} label={filteringBy} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        style={getStyles(option, option, theme)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}