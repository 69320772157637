import React from "react"
import { DoctorCard } from "./doctor-card/doctor-card"
import { doctorCardList } from "./doctor-card-list.module.scss"
import MultiSelectFilter from "../../multi-select-filter/multi-select-filter";
import { useTranslation } from "gatsby-plugin-react-i18next";

// To optimize LCP we mark the first card as eager so the image gets loaded faster
export function DoctorCardList({ doctors = [], filterBy = [], handleSelect }) {
    const translate = useTranslation().t;
    const specialties = {}

    doctors.forEach(doctor => {
        doctor.specialty.forEach(spec => {
            if (specialties[spec]) {
                specialties[spec].push(doctor);
            } else {
                specialties[spec] = [doctor]
            }
        })
    });

    filterBy = filterBy.filter(filter => specialties[filter]);

    return (
        <div style={{ marginTop: "1rem", width: "90%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column" }}>
            <MultiSelectFilter optionsMap={specialties} filteringBy={translate("specialty")} preSelectedOptions={filterBy} onSelect={handleSelect} />
            <div className={doctorCardList}>
                {
                    [1].map(() => {
                        const docs = [];
                        if (!filterBy?.length) {
                            doctors.forEach(doctor => docs.push(<DoctorCard doctor={doctor} key={doctor.name} />));
                        } else {
                            const docsMap = {};
                            filterBy.forEach(filter => {
                                if (specialties[filter]) {
                                    specialties[filter].forEach(doc => {
                                        if (!docsMap[doc.slug]) {
                                            docsMap[doc.slug] = true;
                                            docs.push(<DoctorCard doctor={doc} key={doc.name} />);
                                        }
                                    })
                                }
                            });
                        }

                        return [
                            docs
                        ]
                    })
                }
            </div>
        </div>
    )
}