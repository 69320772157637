import React from "react";
import { graphql } from 'gatsby';
import { DoctorCardList } from "../../components/doctor/doctor-card-list/doctor-card-list";
import Layout from '../../components/layout/layout.jsx';
import { useQueryParam, ArrayParam } from "use-query-params";
import SEO from "../../components/seo"


export const query = graphql`
    query DoctorProfiles($language:String!) {
        allContentfulDoctorCard(filter: {node_locale: {eq: $language}}) {
            nodes {
                name
                specialty
                image {
                    gatsbyImageData(placeholder: DOMINANT_COLOR)
                }
                slug
                gatsbyPath(filePath: "/doctors/{ContentfulDoctorCard.slug}")
                consultationTime
            }
        }
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Doctors({ data: { allContentfulDoctorCard: { nodes } } }) {
    const [specialties, setSpecialties] = useQueryParam("specialty", ArrayParam);
    const handleSpecialtySelect = (specialty) => {
        setSpecialties(specialty);
    }
    return (
        <Layout>
            <SEO title="Doctors"/>
            <DoctorCardList doctors={nodes} filterBy={specialties} handleSelect={handleSpecialtySelect} />
        </Layout >
    );
}