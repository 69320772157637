import * as React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { GatsbyImage } from "gatsby-plugin-image";
import {
    doctorCard,
    doctorCard__action
} from "./doctor-card.module.scss"
import { Trans } from "react-i18next";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import { useI18next } from "gatsby-plugin-react-i18next";

export function DoctorCard({ doctor }) {
    const { language, t: translate } = useI18next();
    const [state, setState] = React.useState(2);
    const {
        name,
        specialty,
        slug,
        image,
        gatsbyPath,
        consultationTime
    } = doctor
    return (
        <Card elevation={state} onMouseOver={() => setState(10)} onMouseOut={() => setState(2)} className={doctorCard}>
            <Link to={gatsbyPath}>
                <CardActionArea>
                    <CardMedia>
                        <GatsbyImage
                            image={image.gatsbyImageData}
                            alt={name}
                        />
                    </CardMedia>
                    <CardContent>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h5"
                            color="text.primary"
                        >
                            {name}
                        </Typography>
                        <Typography
                            align="center"
                            variant="body2"
                            color="text.secondary"
                        >
                            {specialty}
                        </Typography>
                               <Typography
                            align="center"
                            variant="body2"
                            color="text.secondary"
                        >
                            {translate(consultationTime)}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                        <Link className={doctorCard__action} to={"/doctors/" + slug}>
                            <Button className={doctorCard__action} size="medium" variant="contained" align="center">
                                <PersonIcon sx={{ marginRight: "0.5rem" }} />
                                <Typography fontSize="0.8rem" variant="button" color="secondary">
                                    <Trans>{translate("view-profile")}</Trans>
                                </Typography>
                            </Button>
                        </Link>
                            <Link className={doctorCard__action} to={"/doctors/consult/" + slug}>
                            <Button className={doctorCard__action} size="medium" variant="contained" align="center">
                                <ScheduleIcon sx={{ marginRight: "0.5rem" }} />
                                <Typography fontSize="0.8rem" variant="button" color="secondary">
                                    <Trans>{translate("consult-now")}</Trans>
                                </Typography>
                            </Button>
                            </Link>
                    </CardActions>
                </CardActionArea>
            </Link>
        </Card>
    )
}